$font-color: #E43B53; 
$font-family: "Droid Sans", sans-serif;

@mixin gradient-background($c1, $c2, $c3, $c4){
    background: linear-gradient(top, $c1 0%, $c2 50%, $c3 75%, $c4 100%);
    background: -moz-linear-gradient(top, $c1 0%, $c2 50%, $c3 75%, $c4 100%);
    background: -webkit-linear-gradient(top, $c1 0%, $c2 50%, $c3 75%, $c4 100%);
    background: -ms-linear-gradient(top, $c1 0%, $c2 50%, $c3 75%,$c4 100%);
    background: -o-linear-gradient(top, $c1 0%, $c2 50%, $c3 75%,$c4 100%);
    // filter: progid: DXImageTransform.Microsoft.gradient(startColorstr=#{$c4}, endColorstr=#{$c1}, GradientType=1);
    background-color: $c4;
}

body, html {
    height: 100%;
    overflow: hidden;
    @include gradient-background(#27a4de, #26a3dd, #229dd8, #1072B4);
}

.logo {
    z-index: 20;
    position: absolute;
    right: 1.5vw;
    top: 1.5vw;

    img {
        width: 6.5vw;
    }
}

.message {
    z-index: 20;
    color: $font-color;
    font-weight: bold;
    font-family: $font-family;
    text-align: center;
    position: absolute;
    right: 15vw;
    top: 20vw;
    line-height: 10vw;

    .code {
        font-size: 20vw;
    }

    .description {
        font-size: 4vw;
    }
}

.informationbox {
    z-index: 20;
    color: #E43B53;
    display: inline-block;
    text-align: center;
    display: block;
    overflow: auto;
    position: relative;
    padding-left: 50vw;
    padding-top: 13vw;
}

.sizing {
    display: inline-block;
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 0vw !important;
    padding-bottom: 3vw !important;
    border-radius: 0.5vw;

    h1 {
        color: #E43B53 !important;
        padding-top: 2vw !important;
        padding-bottom: 1vw !important;
    }
}

.background {
    position: absolute;
    z-index: 10;
    bottom: 1vw;
    left: 1vw;
    width: 80%;

    img {
        width: 70%;
    }
} 